<template>
    <div class="user">
        <div class="container" style="background:#fff;" v-if="login">
            <Tabs>
            <TabPane label="个人信息" icon="ios-contact">
                <div class="info_channel infobox clear">
				<div class="photo">
					<a href="//my.51.com/user/modifyface/?type=show" target="_blank" id="facePhotoTips"><img align="middle" src="//cdn.51img1.com/v6/sysface/fw_woman_none_130.jpg"></a>				</div>
                    <div class="info">
                        <dl>
                            <dd>
                                <strong>用户名：</strong>
                                <Input v-model="infoMeta.uAccounts" placeholder="请输入用户名" style="width: 200px" />
                            </dd>
                            <dd>
                                <strong>密码：</strong>
                                <a href="javascript:;" @click="modal = true">修改密码</a>
                            </dd>
                            <dd>
                                <strong>性别：</strong>
                                <RadioGroup v-model="infoMeta.sex">
                                    <Radio label="1">男</Radio>
                                    <Radio label="2">女</Radio>
                                </RadioGroup>
                            </dd>
                            <dd>
                                <strong>昵称：</strong>
                                <Input v-model="infoMeta.nickName" placeholder="请输入昵称" style="width: 200px" />
                            </dd>
                             <dd style=" position: relative;">
                                <strong>生日：</strong>
                                <DatePicker v-model="infoMeta.birthday" format="yyyy年MM月dd日" type="date" placeholder="请输入出生年月" style="width: 200px"></DatePicker>
                            </dd>
                            <dd>
                                <strong>地址：</strong>
                                <Input v-model="infoMeta.address" placeholder="请输入地址" style="width: 200px" />
                            </dd>
                            <dd>
                                <strong>安全级别：</strong>
                                <span class="safe_grade safe_grade_low"><i></i>低</span>
                            </dd>
                            <dd>
                                <div class="save-infor" @click="saveInfo">保存</div>
                            </dd>
                        </dl>
                    </div>
			</div>
            </TabPane>
            <TabPane label="防沉迷认证" icon="ios-cog">
               <div class="two" >
                  <div  v-if="current == 0"  class="two-first">
                    <div class="two-first-one">
                      <p>填写实名资料</p>
                      <p>完成</p>
                    </div>
                    <div class="two-first-two">
                       <Form ref="formCustom" :model="formCustom" :rules="ruleCustom" :label-width="80">
                            <FormItem label="您的姓名:" prop="age">
                                <input type="text" v-model="formCustom.name">
                            </FormItem>
                            <FormItem label="身份证号:" prop="number">
                                <input type="text" v-model="formCustom.age">
                            </FormItem>
                            <FormItem style="margin:0 auto;width:80%;text-align:center;" class="mamama">
                                <Button type="primary" @click="handleSubmit('formCustom')">提交</Button>
                                <Button @click="handleReset('formCustom')" style="margin-left: 8px">重置</Button>
                            </FormItem>
                        </Form>
                    </div>
                    <div>
                       <p class="alert_txt"><b>温馨提示：</b><br><strong>
                            注意：51游戏平台所有游戏仅适合18周岁以上人员进入，系统会判断您填写的身份证中的出生日期，若未满18周岁，您将在游戏内受到防沉迷限制，
                            请谨慎填写！</strong><br>
                            为了积极响应国家文化部出台的《网络游戏管理暂行办法》文件中第二十一条 网络游戏运营企业应当要求网络游戏用户使用有效身份证件进行实名注册，
                            并保存用户注册信息。<br><strong>51游戏平台</strong>从即日起实施 <strong>游戏帐号防沉迷认证</strong>，
                            我们以实际行动保障广大玩家们的权益。<br>
                            感谢你们一路的支持！
                        </p>
                    </div>
                  </div>
                  <div v-if="current == 1" class="two-first">
                     <div class="two-last-one">
                        <img src="../assets/images/IDcard.jpg" alt="">
                        <p>您已认证成功了！</p>
                     </div>
                  </div>
                     <!-- <p>当前正在进行第 {{ current + 1 }} 步</p>
                    <Steps :current="current">
                        <Step title="填写实名资料"></Step>
                        <Step title="完成"></Step>
                    </Steps>
                    <Card v-if="current == 0">
                        <br/>
                        <Form ref="formCustom" :model="formCustom" :rules="ruleCustom" :label-width="80">
                            <FormItem label="您的姓名" prop="age">
                                <Input type="text" v-model="formCustom.name"></Input>
                            </FormItem>
                            <FormItem label="身份证号" prop="age">
                                <Input type="text" v-model="formCustom.age"></Input>
                            </FormItem>
                            <FormItem>
                                <Button type="primary" @click="handleSubmit('formCustom')">提交</Button>
                                <Button @click="handleReset('formCustom')" style="margin-left: 8px">重置</Button>
                            </FormItem>
                        </Form>
                       
                    </Card> -->
                </div>
            </TabPane>
            </Tabs>
        </div>
        <div v-if="!login">
            <router-link :to="{name:'home'}" class="please"><span>请点击登录</span></router-link>
        </div>
        <footer-info></footer-info>
        <Modal
            v-model="modal"
            title="修改密码"
            @on-ok="editPassword">
            <Form ref="formInline" :model="formInline" :rules="ruleInline" inline>
                <FormItem prop="user">
                    <Input type="text" v-model="formInline.user" placeholder="用户名/手机号">
                        <Icon type="ios-person-outline" slot="prepend"></Icon>
                    </Input>
                </FormItem>
                <FormItem prop="password">
                    <Input type="password" v-model="formInline.password" placeholder="原密码">
                        <Icon type="ios-lock-outline" slot="prepend"></Icon>
                    </Input>
                </FormItem>
                <FormItem prop="password2">
                    <Input type="password" v-model="formInline.editPass" placeholder="新密码">
                        <Icon type="ios-lock-outline" slot="prepend"></Icon>
                    </Input>
                </FormItem>
            </Form>
        </Modal>
    </div>
</template>


<script>
import axios from "axios";
import base from "../api/base.js";
import FooterInfo from "../components/footer";
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  components: {
    FooterInfo
  },
  computed: {
    ...mapState(["login", "authStatus", "info"]),
    ...mapGetters(["infoMeta"])
  },
  data() {
    const validateAge = (rule, value, callback) => {
      // var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      // if (reg.test(value) === false) {
      //   this.$Message.info("身份证输入不合法");
      //   return false;
      // }
      callback();
    };
    return {
      current: 0,
      modal: false,
      formInline: {
        user: "",
        password: "",
        editPass: ""
      },
      ruleInline: {
        user: [{ required: true, message: "请输入帐号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        editPass: [
          { required: true, message: "请输入要修改的密码", trigger: "blur" }
        ]
      },
      formCustom: {
        name: "",
        age: ""
      },
      ruleCustom: {
        name: [{ required: true, trigger: "blur" }],
        age: [{ required: true, validator: validateAge, trigger: "blur" }]
      }
    };
  },
  created() {
    this.current =this.authStatus
  },
  methods: {
    ...mapMutations(["set_login", "set_authStatus"]),
    saveInfo() {
      var birthday = this.infoMeta.birthday;
      if (birthday.length == 10) {
        axios
          .get(base.api + "saveUser", { params: this.infoMeta })
          .then(res => {
            if (res.data.code == "200") {
              this.$Message.info("保存成功!");
            } else {
              this.$Message.error(res.data.msg ? res.data.msg : "保存失败!");
            }
          });
      } else {
        let Y = birthday.getFullYear() + "-";
        let M =
          (birthday.getMonth() + 1 < 10
            ? "0" + (birthday.getMonth() + 1)
            : birthday.getMonth() + 1) + "-";
        let D =
          birthday.getDate() + 1 < 10
            ? "0" + (birthday.getDate() + 1)
            : birthday.getDate() + 1;
        birthday = Y + M + D;
        this.infoMeta.birthday = birthday;
         axios
          .get(base.api + "saveUser", { params: this.infoMeta })
          .then(res => {
            if (res.data.code == "200") {
              this.$Message.info("保存成功!");
            } else {
              this.$Message.error(res.data.msg ? res.data.msg : "保存失败!");
            }
          });
      }
    },
    editPassword() {
      this.$refs["formInline"].validate(valid => {
        let that = this;
        if (valid) {
          let data = {
            uAccounts: that.formInline.user,
            uPass: that.formInline.password,
            editPass: that.formInline.editPass
          };
          axios.get(base.api + "editPass", { params: data }).then(res => {
            if (res.data.code == "200") {
              this.$Message.info("修改成功!");
              // 删除缓存
            //   this.set_login("");
            } else {
              this.$Message.error(res.data.msg ? res.data.msg : "修改失败!");
            }
          });
        } else {
          this.$Message.error("验证失败");
        }
      });
    },
    next() {
      this.current += 1;
    },
    handleSubmit(name) {
      this.$refs[name].validate(valid => {
        let that = this;
        if (valid) {
          let data = {
            uAccounts: that.login,
            uName: that.formCustom.name,
            identityID: that.formCustom.age
          };
          axios.get(base.api + "authentication", { params: data }).then(res => {
            if (res.data.code == "200") {
              that.$Message.info("认证成功!");
              that.set_authStatus("1");
              that.next();
            } else {
              that.$Message.error(res.data.msg ? res.data.msg : "认证失败!");
            }
          });
        } else {
          that.$Message.error("认证失败!");
        }
      });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    }
  }
};
</script>
<style>
.info dd {
  margin-bottom: 12px;
}
.user {
  margin-top: 50px;
}

.please {
  text-align: center;
  width: 100%;
  display: block;
  font-size: 24px;
}
.please span {
  display: block;
  margin: 0 auto;
  width: 200px;
  height: 60px;
  line-height: 60px;
  border-radius: 5px;
  color: #fff;
  background: #f86503;
}
.two {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.two .two-first{
width: 50%;
margin:0 auto;
height: 100%;
}
.two .two-first .two-first-one{
  border:1px solid #ccc;
  width: 100%;
  height: 47px;
  margin:0 auto;
  display: flex;
  flex-direction: row;
}
.two .two-first .two-first-one p{
  position: relative;
  display: block;
  width: 50%;
  height:46px;
  line-height: 46px;
  font-size: 14px;
  text-align: center;
}
.two .two-first .two-first-one p:first-child:before{
  content: "";
  position: absolute;
  right: -16px;
  top: 7px;
  width: 31px;
  height: 31px;
  transform: rotate(225deg);
   background: #00aeff;
}
.two .two-first .two-first-one p:first-child{
  background: #00aeff;
  color: #fff;
}
.two .two-first .two-first-two{
  margin-top:30px;
}
.two .two-first .two-last-one{
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top:30px;
  padding-left:5px;
  background: #fcfafa;
}
.two .two-first .two-last-one img{
  margin-top:9px;
  widows: 60px;
  height: 42px;
}
.two .two-first .two-last-one p{
  line-height: 60px;
  color: #6dba39;
  padding-left:10px;
  font-size: 16px;
}

.ivu-steps {
  padding: 40px 0;
}
.alert_txt {
  font-size: 12px;
  color: #808080;
  padding-top: 20px;
  line-height: 1.6;
}
.alert_txt strong {
  color: #eb6100;
  font-weight: normal;
}

.info_channel {
  border-top: 1px dashed #e5e4e4;
  margin: 0 0 0 2px;
  padding: 10px 0 10px 0;
  height: 100%;
}
.infobox {
  padding: 0 0 28px 0;
  background: url(../assets/images/num_bg.gif) 668px 29px no-repeat;
  border-top: none;
  background-color: white;
}
.infobox .photo,
.infobox .info {
  float: left;
}
.infobox .day_num {
  float: right;
  margin: 63px 210px 0 0;
  font: 24px arial;
  color: #b1b1b1;
}
.infobox .photo {
  margin-right: 15px;
  display: inline;
}
.infobox .info {
  width: 300px;
}
.infobox .info dl {
  margin-top: -3px;
  position: relative;
}
.infobox .info dt {
  width: 285px;
  height: 20px;
  overflow: hidden;
  margin: 8px 0;
}
.infobox .info dt strong {
  font-size: 12px;
  margin-right: 10px;
  font-weight: normal;
}
.mamama .ivu-form-item-content{
  margin-left:0 !important;
}
.infobox .info dt a {
  font-size: 12px;
}
.infobox .info dt span {
  color: #ff0000;
}
.infobox .info dt > a {
  color: #ff0000;
  color: #000;
  text-decoration: underline;
  float: right;
  *margin-top: -20px;
}
.infobox .info dd {
  font-size: 12px;
  line-height: 20px;
}
.infobox .info dd.level {
  padding: 8px 0;
}
.infobox .info dd label {
  float: left;
  width: 85px;
}
.infobox .info dd span {
  margin: 0 10px 0 0;
}
.infobox .info dd.level .img_pic {
  float: left;
  padding: 0 5px 0 0;
  position: relative;
  height: 22px;
}
.infobox .info dd.level .pop_shadow {
  top: 23px;
  left: -20px;
  color: #333;
  white-space: nowrap;
}
.infobox .info dd.level .img_pic .pop_shadow {
  display: none;
}
.infobox .info dd.level .img_pic_hover .pop_shadow {
  display: block;
}
.infobox .info dd.level .pop_shadow a {
  color: #00aeff;
}
.save-infor {
  width: 60px;
  height: 30px;
  background: #00aeff;
  text-align: center;
  line-height: 30px;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
}
.ivu-select-dropdown {
  /* width: 300px !important; */
  top: -200px !important;
  left: 250px !important;
}
</style>

